:root {
    /* สีพื้นหลัง */
    --bg-color-light: #E0F7FA; /* ฟ้าสว่าง */
    --bg-color-content: #FFFFFF; /* ขาว */

    /* สีข้อความ */
    --text-main: #0277BD; /* น้ำเงินเข้ม */
    --text-secondary: #B3E5FC; /* ฟ้าซีด */
    --text-heading: #01579B; /* ฟ้าเข้ม */

    /* สีลิ้ง */
    --link-normal: #0288D1; /* ฟ้าน้ำเงิน */
    --link-hover: #01579B; /* ฟ้าเข้ม */

    /* Header / Top Bar */
    --header-bg: #0288D1; /* ฟ้าน้ำเงิน */
    --header-text: #FFFFFF; /* ขาว */

    /* Navbar Top */
    --navbar-top-bg: #01579B; /* ฟ้าเข้ม */
    --navbar-top-text: #FFFFFF; /* ขาว */
    --navbar-top-link: #B3E5FC; /* ฟ้าซีด */

    /* Navbar Left */
    --navbar-left-bg: #0277BD; /* น้ำเงินเข้ม */
    --navbar-left-text: #FFFFFF; /* ขาว */
    --navbar-left-link: #B3E5FC; /* ฟ้าซีด */

    /* Footer */
    --footer-bg: #0288D1; /* ฟ้าน้ำเงิน */
    --footer-text: #FFFFFF; /* ขาว */

    /* ปุ่ม */
    --button-main: #039BE5; /* ฟ้าสดใส */
    --button-hover: #0277BD; /* ฟ้าน้ำเงินเข้ม */
}

