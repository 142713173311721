html, body {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
	min-height: 100vh;
}
header {
	top: 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	width: 100%;
	height: 60px;
	background-color: var(--header-bg);
	.header-logo {
		color: var(--text-secondary); 
		font-weight: bold;
	}
}


/* Start Navbar */

nav {
	left: 0px;
	top: 60px;
	width: 250px;
	background-color: var(--navbar-left-bg);
	height: 100%;
	position: fixed
}
nav > .parent-menu > .nav-menu-title {
	padding-inline: 10px;
	justify-content: space-between;
	font-weight: bold;
	color: var(--text-main);
	height: 40px;
	background-color: var(--bg-color-light);
	width: 250px;
	display: flex;
	align-items: center;
}
nav > .parent-menu > .nav-menu-title h6 {
	margin: 0;
	padding: 0;
}
nav > .parent-menu > .nav-menu-title  + .child-menu > ul {
	list-style: none;
	padding: 5px;
	margin: 0px;
}
nav > .parent-menu > .nav-menu-title + .child-menu > ul > li {
	padding-block: 3px;
	padding-inline: 10px;
	color: var(--bg-color-light);

}
.child-menu >* .sub-menu {
	display: none;
}
/* End Navbar */

main {
	margin-left: 250px;
	margin-right: 250px;
	padding: 10px;
	max-width: 100vw;
	height: 100%;
	margin-top: 50px;
}
main > .navbar-top {
	display: flex;
	align-items: center;
	padding: 10px;
	width: 100%;
	height: 40px;
	background-color: var(--bg-color-light);
	color: var(--navbar-left-bg);
}
main > .navbar-top > span {
	padding: 10px;
}
.wrapper-onlineuser {
	width: 250px;
	min-height: 100%;
	background-color: var(--navbar-left-bg);
	color: var(--text-secondary);
	position: fixed;
	top: 60px;
	right: 0;
	bottom: 0;
	z-index: -1;
}
.wrapper-onlineuser > .title-online {
	background-color: var(--bg-color-light);
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--text-main);
}
.wrapper-onlineuser > .user-list {
	padding: 3px 20px;
}
.child-menu >* a {
	color: var(--text-secondary);
	text-decoration: none;
}
.pagination {

}
.pagination > .pagination-page-number {
	padding: 5px 10px;
	color: var(--text-heading);
	border: var(--text-heading) 1px solid; 
	border-radius: 5px;
	margin: 3px;
	cursor: pointer;
	transition: .8s;
}
.pagination > .pagination-page-number:hover {
	background-color: var(--text-heading);
	color: var(--text-secondary);
}
.dropdown-profile-setting {
	z-index: 10;
	right: 0;
	position: absolute;
	top: 42px;
	height: fit-content;
	width: 200px;
	background-color: var(--navbar-top-bg); 
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	
}
.dropdown-profile-setting  > ul {
	margin: 0;
	padding: 10px 20px;
	list-style: none;
	li {
		padding: 5px;
	}
}
.wrapper-onlineuser > .user-list:hover {
	opacity: 0.5;
	cursor: pointer;
	transition: .5s;
}